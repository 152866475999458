import * as React from 'react';
import styled from 'styled-components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { NumberFormat, Row } from 'saladsoft-primitive';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { differenceInDays, format } from 'date-fns';
import { enUS, ko, ja, zhCN } from 'date-fns/locale';
import { useStoreWrapper } from 'stores/StoreHelper';
import { convertStringToDate } from 'libs/helper/utils';
import { ListingDto, ListingPriceDto, UserProfileDto } from 'services/data-contracts';
import { BookInfo } from 'types/CommonTypes';
import { useTranslation } from 'react-i18next';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import CalendarModal from './CalendarModal';
import { Popover } from '@mui/material';
import { getUrlParam } from 'libs/helper/utils';

const DetailReservationBox = ({
  listingPrice,
  listing,
  priceType,
  onClick,
}: {
  listingPrice?: ListingPriceDto;
  listing?: ListingDto;
  priceType: 'actual' | 'lowest';
  onClick: () => void;
}) => {
  const { t } = useTranslation();
  const navigate = useLocaleNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { uiStore } = useStoreWrapper();
  const guestsParam = searchParams.get('guests');
  const startDateParam = searchParams.get('startdate');
  const endDateParam = searchParams.get('enddate');

  const { calendarStore } = useStoreWrapper();

  const [nights, setNights] = useState<number | undefined>(undefined);
  const [days, setDays] = useState<number | undefined>(undefined);
  const [showMaintenance, setShowMaintenance] = useState(false);
  const [bookInfo, setBookInfo] = useState<BookInfo | undefined>(undefined);
  const [userInfo, setUserInfo] = useState<UserProfileDto | undefined>(undefined);

  const maintenanceRef = useRef<HTMLDivElement>(null);

  const handleClickDate = () => {
    calendarStore.setMobileCalendarShow(true, true, true);
  };

  const handleClickBook = () => {
    onClick();
  };

  const navigateToBook = () => {
    const startDate = getUrlParam('startdate');
    const endDate = getUrlParam('enddate');
    const guests = getUrlParam('guests');

    navigate(`/book/${listing?.id}?startdate=${startDate}&enddate=${endDate}&guests=${guests}`);
  };

  useEffect(() => {
    console.log('bookInfo:', bookInfo);
  }, [bookInfo]);

  useEffect(() => {
    if (startDateParam && endDateParam) {
      const start = new Date(startDateParam);
      const end = new Date(endDateParam);
      if (start < end) {
        const nights = differenceInDays(end, start);
        setBookInfo({
          startDate: startDateParam,
          endDate: endDateParam,
          guests: Number(guestsParam) || 2,
        });
        setNights(nights);
        setDays(nights + 1);
      } else {
        setNights(undefined);
        setDays(undefined);
        setBookInfo(undefined);
      }
    }
  }, [startDateParam, endDateParam]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (maintenanceRef.current && !maintenanceRef.current.contains(event.target as Node)) {
        setShowMaintenance(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      calendarStore.setMobileCalendarShow(false, false, false);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <CardContainer>
      <Row padding="10px 0 10px20px" justifyContent="space-between" width="100%" gap={30}>
        <Row justifyContent="space-between" flexDirection="column" alignItems="flex-start">
          <Row justifyContent="flex-start" alignItems="center" gap={10} fontSize={12} flex="0.7">
            <PriceWrapper>
              <>
                {listingPrice ? (
                  <>
                    <Row>
                      <div>₩</div>
                      <NumberFormat value={(listingPrice?.price as number) ?? 0} />
                      <>{priceType === 'lowest' && <>~</>}</>
                    </Row>
                  </>
                ) : (
                  <>-</>
                )}
              </>
              <InfoOutlinedIcon
                onClick={() => setShowMaintenance(!showMaintenance)}
                style={{ color: '#1C51B6', width: 16, height: 16 }}
              />
              {showMaintenance && (
                <PopOverStyle ref={maintenanceRef}>{t('maintenance')}</PopOverStyle>
              )}
            </PriceWrapper>
            <div style={{ color: '#8E8E8E' }}>
              {nights || '-'}
              {t('Nights')} {days || '-'}
              {t('Days')}
            </div>
            <CapacityText>
              / {bookInfo?.guests || '2'}
              {t('people')}
            </CapacityText>
          </Row>
          <div
            style={{ color: '#8E8E8E', fontSize: 13, textDecoration: 'underline', marginTop: 3 }}
            onClick={handleClickDate}
          >
            {bookInfo?.startDate && bookInfo?.endDate
              ? `${convertStringToDate(bookInfo?.startDate)} ~ ${convertStringToDate(bookInfo?.endDate)}`
              : t('please select date')}
          </div>
        </Row>
        <div
          onClick={() => {
            if (bookInfo?.startDate && bookInfo?.endDate) {
              handleClickBook();
            }
          }}
          style={{
            flex: 1,
            padding: '0 10px',
            textAlign: 'right',
            fontWeight: 600,
            fontSize: 19,
            color: bookInfo?.startDate && bookInfo?.endDate ? '#fff' : '#555',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '40px',
            width: '100%',
            paddingLeft: '10px',
            borderLeft: '1px solid #555',
          }}
        >
          {t('book')}
        </div>
      </Row>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--1-f-1-e-1-d, #1f1e1d);
  display: flex;
  max-width: 100%;
  overflow: hidden;
  font-family: Pretendard, sans-serif;
  padding: 20px 0 20px 20px;
`;

const InfoSection = styled.div`
  align-self: stretch;
  display: flex;
  padding-left: 20px;
  flex-direction: column;
  justify-content: start;
  flex: 1;
  flex-basis: 60px;
  margin: auto 0;
`;

const CapacityText = styled.div`
  color: var(--Grayscale-50, #8e8e8e);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.03px;
  align-self: stretch;
  margin: auto 0;
`;

const PopOverStyle = styled.div`
  position: fixed;
  left: 40px;
  bottom: 70px;
  width: 100%;
  max-width: 200px;
  max-height: 100px;
  white-space: pre-wrap;
  background-color: #fff;
  color: #000;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.03px;
  display: none;
  flex-direction: column;
  align-items: center;
  box-shadow:
    0px 2px 12px 0px rgba(0, 0, 0, 0.1),
    0px 2px 8px 0px rgba(0, 0, 0, 0.1);

  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #fff;
    /* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1)); */
    filter: none;
  }
`;

const PriceWrapper = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 17px;
  color: #f8f8f8;
  font-weight: 600;
  white-space: nowrap;
  letter-spacing: -0.04px;
  justify-content: center;
  margin: auto 0;
  position: relative;

  &:hover ${PopOverStyle} {
    display: inline-flex;
  }
`;

export default DetailReservationBox;
