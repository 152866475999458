import React, { useState } from 'react';
import styled from 'styled-components';
import { InputField } from 'saladsoft-primitive';
import { useTranslation } from 'react-i18next';

interface BirthdayFieldProps {
  label?: string;
  value: string;
  error?: string;
  helperText?: string;
  infoText?: string;
  placeholder?: string;
  required?: boolean;
  onChange: (value: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
  FormHelperTextProps?: {
    component?: React.ElementType;
    sx?: any;
  };
}

// 에러 메시지
const errorMessages = {
  required: 'signup.guest_info_required',
  invalidBirthdayFormat: 'signup.invalidBirthdayFormat',
  invalidBirthdayValue: 'signup.invalidBirthdayValue',
  incompleteBirthdayFormat: 'signup.incompleteBirthdayFormat',
};

export const BirthdayField: React.FC<BirthdayFieldProps> = ({
  label,
  value,
  error,
  helperText,
  infoText,
  placeholder,
  required,
  onChange,
  onBlur,
  disabled = false,
  FormHelperTextProps,
}) => {
  const { t } = useTranslation();

  // 생년월일 포맷팅 (YYYY-MM-DD)
  const formatBirthday = (value: string): string => {
    const numbers = value.replace(/[^\d]/g, '');
    const trimmed = numbers.substring(0, 8);

    if (trimmed.length > 6) {
      return `${trimmed.substring(0, 4)}-${trimmed.substring(4, 6)}-${trimmed.substring(6)}`;
    } else if (trimmed.length > 4) {
      return `${trimmed.substring(0, 4)}-${trimmed.substring(4)}`;
    } else if (trimmed.length > 0) {
      return trimmed;
    }
    return '';
  };

  // 생년월일 유효성 검사
  const validateBirthday = (birthday: string, originalInput: string) => {
    let errorMessage = '';

    if (!birthday) {
      errorMessage = t(errorMessages.required);
      return { isValid: false, errorMessage };
    }

    // 숫자와 하이픈만 허용하는 정규식 검사
    if (!/^[\d-]*$/.test(originalInput)) {
      errorMessage = t(errorMessages.invalidBirthdayFormat);
      return { isValid: false, errorMessage };
    }

    // YYYY-MM-DD 형식 검사 (정확한 날짜 유효성 검사)
    const dateRegex = /^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;

    if (birthday.length === 10) {
      if (!dateRegex.test(birthday)) {
        errorMessage = t(errorMessages.invalidBirthdayFormat);
        return { isValid: false, errorMessage };
      }

      const [_, year, month, day] = birthday.match(dateRegex) || [];
      const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

      if (
        date.getFullYear() !== parseInt(year) ||
        date.getMonth() !== parseInt(month) - 1 ||
        date.getDate() !== parseInt(day)
      ) {
        errorMessage = t(errorMessages.invalidBirthdayValue);
        return { isValid: false, errorMessage };
      }
    } else {
      errorMessage = t(errorMessages.incompleteBirthdayFormat);
      return { isValid: false, errorMessage };
    }

    return { isValid: true, errorMessage: '' };
  };

  // handle 함수 통합
  const handleBirthdayInput = (inputValue: string, isBlur: boolean = false) => {
    // 입력값과 blur 이벤트 여부를 파라미터로 받음
    const formattedValue = formatBirthday(inputValue);

    if (isBlur) {
      const { isValid, errorMessage } = validateBirthday(formattedValue, inputValue);
      onBlur?.();
    }

    onChange(formattedValue); // 값 변경 처리
  };

  return (
    <FieldWrapper>
      {label && (
        <FieldLabel>
          {label}
          {required && <RequiredMark>*</RequiredMark>}
        </FieldLabel>
      )}
      <InputField
        placeholder={placeholder}
        id="birthday"
        value={value}
        onChange={(value: string) => handleBirthdayInput(value, false)}
        onPaste={(e: React.ClipboardEvent<HTMLInputElement>) =>
          handleBirthdayInput(e.clipboardData.getData('text'), false)
        }
        disabled={disabled}
        onBlur={() => handleBirthdayInput(value, true)}
        error={!!error}
        helperText={error}
        FormHelperTextProps={FormHelperTextProps}
      />
      {infoText && <InfoText>{infoText}</InfoText>}
    </FieldWrapper>
  );
};

const FieldWrapper = styled.div``;

const FieldLabel = styled.div`
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.04em;
  color: '#1d1d1d';
`;

const RequiredMark = styled.span`
  color: #f35064;
  margin-left: 4px;
`;

const InfoText = styled.div`
  font-size: 12px;
  color: #1c51b6;
  margin: 6px 0 0 0;
  display: flex;
  align-items: ceneter;
  gap: 4px;

  &::before {
    content: '';
    width: 16px;
    height: 16px;
    background-image: url('https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/cbcdb696634287552daa362ff522b6ef443c45b9fb05d1dde8261d50272140a0?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&');
    background-size: contain;
    background-repeat: no-repeat;
    flex-shrink: 0;
  }
`;

export default BirthdayField;
