import BodyText from 'components/commons/BodyText';
import CancellationPolicy from 'components/commons/CancellationPolicy';
import Subtitle from 'components/commons/Subtitle';
import Box from 'components/layouts/Box';
import AgreementForm from 'components/pages/bookPage/ActionButton';
import AdditionalOptions from 'components/pages/bookPage/AdditionalOptions';
import Agreements from 'components/pages/bookPage/Agreements';
import BasicInfoContainer from 'components/pages/bookPage/BasicInfoContainer';
import PaymentMethods from 'components/pages/bookPage/PaymentMethods';
import PriceDetail from 'components/pages/bookPage/PriceDetail';
import PageContainer from 'containers/pc/PageContainer';
import React, { useEffect, useState } from 'react';
import { Row, useMediaContext, useModelStateContext } from 'saladsoft-primitive';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import InputField from 'components/input/InputField';
import CaptionText from 'components/commons/CaptionText';
import { Form, useLocation, useParams } from 'react-router-dom';
import ServiceHelper from 'stores/ServiceHelper';
import { getUrlParam } from 'libs/helper/utils';
import {
  ListingPriceDto,
  ListingDto,
  UserInfoDto,
  ReservationDto,
  TosspaymentRequestDto,
  UserCouponDto,
  VoucherDto,
  ReservationOptionDto,
} from 'services/data-contracts';
import i18n from 'i18n';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import { toast } from 'react-toastify';
import { ROOT_HOST, TOSSPAY_KEY } from 'stores/AppStore';
import BTButton from 'components/input/BTButton';
import { DiscountSection } from 'components/pages/bookPage/points/discount/DiscountSection';
import { validationHelper } from 'libs/helper/validationHelper';
import GuestInfo from 'components/pages/bookPage/ReservationInfo';
import { useStoreWrapper } from 'stores/StoreHelper';
import CouponListModal from 'components/pages/bookPage/points/discount/CouponListModal';
import { BarLoader } from 'react-spinners';
import { HideGnb } from 'components/commons/mobile/HideGnb';
import MyInfoTabName from 'components/commons/mobile/MyInfoTabName';

const BookPage = () => {
  const isHideGNB = HideGnb(window.location.pathname);
  const location = useLocation();
  const { t } = useTranslation();
  const { listingId } = useParams();
  const { isMobile } = useMediaContext();
  const { setState, serviceModel, updateServiceModel, setMultipleState, stateModel } =
    useModelStateContext<ReservationDto>();
  const { uiStore } = useStoreWrapper();

  const [listing, setListing] = useState<ListingDto | undefined>(undefined);
  const [listingPrice, setListingPrice] = useState<ListingPriceDto | undefined>(undefined);
  const [isForeignCard, setIsForeignCard] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [checkedCoupon, setCheckedCoupon] = useState<UserCouponDto | undefined>(undefined);
  const [voucher, setVoucher] = useState<VoucherDto | undefined>(undefined);
  const [myInfo, setMyInfo] = useState<UserInfoDto | undefined>(undefined);
  const [busy, setBusy] = useState(false);
  const [busyLoading, setBusyLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<ReservationOptionDto[]>([]);

  const getMyInfo = () => {
    ServiceHelper.myAccountService?.getMyAccount().then((res) => {
      setMyInfo(res.data);
    });
  };

  const validate = () => {
    const countryCode = validationHelper.required(stateModel?.guestCountryCode);
    const phoneNumber = validationHelper.number(stateModel?.guestPhoneNumber);
    const email = validationHelper.email(stateModel?.guestEmail);
    const firstName = validationHelper.required(stateModel?.guestFirstName);
    const lastName = validationHelper.required(stateModel?.guestLastName);
    const language = validationHelper.required(stateModel?.languageCode);

    setIsValid(
      isAgreementChecked &&
        isTermsChecked &&
        countryCode &&
        phoneNumber &&
        email &&
        firstName &&
        lastName &&
        language,
    );
  };

  const createReservation = () => {
    setBusy(true);
    // console.log('@@@@', serviceModel.current);
    // 회원 예약 생성
    if (myInfo) {
      ServiceHelper.myReservationService
        ?.createReservation(serviceModel.current as ReservationDto)
        .then((res) => {
          if (res.status === 200) {
            requestTossPayment(res.data);
          } else {
            const newError = res as any;
            const message = newError.response.data.errorCode;
            toast.error(t(message ?? 'error_reservation'));
            setBusy(false);
          }
        });
    } else {
      ServiceHelper.nonReservationService
        ?.createReservationForNonMember(serviceModel.current as ReservationDto)
        .then((res) => {
          if (res.status === 200) {
            requestTossPayment(res.data);
          } else {
            const newError = res as any;
            const message = newError.response.data.errorCode;
            toast.error(t(message));
            setBusy(false);
          }
        });
    }
  };

  const requestTossPayment = async (result: TosspaymentRequestDto) => {
    try {
      if (result.tossClientKey) {
        const tossPayments = await loadTossPayments(result.tossClientKey as string);
        // 비회원 결제 성공 후, 예약 정보를 로컬 스토리지에 저장
        await localStorage.setItem(
          `reservation${result.reservation?.id}`,
          JSON.stringify(result.reservation),
        );

        await tossPayments.requestPayment('카드', {
          amount: result.amount as number,
          useInternationalCardOnly: isForeignCard,
          orderId: result.orderNo as string,
          orderName: result.reservation?.listingName as string,
          customerName: ((result.reservation?.guestFirstName as string) +
            result.reservation?.guestLastName) as string,
          successUrl: `${ROOT_HOST}/${i18n.language}/payment-success?reservationkey=${result.reservation?.id}`,
          failUrl: `${ROOT_HOST}/${i18n.language}/fail/`,
        });
      } else {
        toast.error(t('payment_cancelled_with_toss_client_key'));
        setBusy(false);
      }
    } catch (error) {
      // 결제창이 닫힐 때 발생하는 에러 처리
      toast.error(t('payment_cancelled'));
      setBusy(false);
    }
  };

  const handleClickDiscountItem = (type: string, value: string | undefined) => {
    switch (type) {
      // Lc4Tiiy6UCAV
      case 'voucher':
        if (value) {
          ServiceHelper.voucherService
            ?.validateVoucher({
              code: value,
              listingId: Number(listingId),
              arrivalDate: stateModel?.arrivalDate as string,
            })
            .then((res) => {
              if (res.status === 200) {
                setVoucher(res.data);
                updateServiceModel('voucherCode', res.data.code);
              } else {
                toast.error(t('voucher_not_found'));
              }
            })
            .catch(() => {
              toast.error(t('voucher_not_found'));
            });
        }

        break;

      case 'coupon':
        uiStore?.modal.show({
          title: t('couponList'),
          style: { width: 440, height: '100%', maxHeight: '80vh' },
          children: <CouponListModal />,
          onConfirmed: (data) => {
            const checkedCoupon = data as UserCouponDto;
            setCheckedCoupon(checkedCoupon);
            setState('couponCode', checkedCoupon.code);
          },
        });
        break;

      case 'points':
        if (value) {
          setState('usePoint', Number(value));
        }
        break;
    }
  };

  useEffect(() => {
    if (myInfo) {
      setMultipleState({
        guestCountryCode: myInfo?.profile?.countryCode,
        guestFirstName: myInfo?.profile?.firstName,
        guestLastName: myInfo?.profile?.lastName,
        guestEmail: myInfo?.username,
        guestPhoneNumber: myInfo?.profile?.phoneNumber?.replace(/-/g, ''),
      });
    }
  }, [myInfo]);

  useEffect(() => {
    validate();
    console.log('myInfo', stateModel);
  }, [isAgreementChecked, isTermsChecked, stateModel]);

  useEffect(() => {
    if (voucher) {
      updateServiceModel('voucherCode', voucher.code);
    }
    if (checkedCoupon) {
      updateServiceModel('couponCode', checkedCoupon.code);
    }
    // if (selectedOptions.length > 0) {
    //   updateServiceModel('options', selectedOptions);
    // }
  }, [voucher, checkedCoupon]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  useEffect(() => {
    if (myInfo) {
      setMultipleState({
        guestCountryCode: myInfo.profile?.countryCode,
        // guestFirstName: myInfo.profile?.name,
        // guestLastName: myInfo.profile?.name,
        guestEmail: myInfo.username,
        guestPhoneNumber: myInfo.profile?.phoneNumber?.replace(/-/g, ''),
      });
    }
  }, [myInfo]);

  useEffect(() => {
    const listingIdNumber = Number(listingId);
    const startDate = getUrlParam('startdate');
    const endDate = getUrlParam('enddate');
    const guests = getUrlParam('guests');

    setMultipleState({
      listingId: listingIdNumber,
      arrivalDate: startDate ?? '',
      departureDate: endDate ?? '',
      adults: Number(guests) || 2,
      languageCode: i18n.language.toUpperCase(),
    });

    if (startDate && endDate && listingIdNumber) {
      setBusyLoading(true);

      ServiceHelper.listingService
        ?.getPriceByListing(listingIdNumber, {
          startingDate: startDate ?? '',
          endingDate: endDate ?? '',
          adults: Number(guests) || 2,
        })
        .then((res) => {
          if (res.status === 200) {
            setListingPrice(res.data);
            updateServiceModel('totalAmount', res?.data?.totalPrice ?? 0);
          } else {
            toast.error(t(''));
          }
        })
        .finally(() => {
          setBusyLoading(false);
        });

      ServiceHelper.listingService
        ?.getListingById(listingIdNumber, { locale: i18n.language })
        .then((res) => {
          setListing(res.data);
        });
    }
  }, [listingId, location.search]);

  useEffect(() => {
    getMyInfo();
  }, []);

  return (
    <PageContainer>
      {busyLoading && (
        <LoadingWrapper>
          <img src="/images/butlerlee-loading.gif" alt="loading" style={{ width: 100 }} />
          <BarLoader />
        </LoadingWrapper>
      )}
      <div className="page-container" style={{ paddingBottom: 48, padding: '0 20px' }}>
        <RowWrapper alignItems="flex-start" gap="48px">
          <div style={{ width: isMobile ? '100%' : '70%' }}>
            {isMobile ? (
              <MyInfoTabName title={t('book')} />
            ) : (
              <Subtitle type="A" style={{ fontSize: 28, margin: '0 0 24px 0' }}>
                {t('book')}
              </Subtitle>
            )}
            <BasicInfoContainer bookInfo={stateModel} priceInfo={listingPrice} listing={listing} />
            {/* <div style={{ maxWidth: 792, marginTop: 40 }}> */}
            <FormContainer>
              <GuestInfo
                userInfo={myInfo}
                onChange={(data) => {
                  const recentData = {
                    ...data,
                    listingId: Number(listingId),
                  };
                  setMultipleState(recentData as Partial<ReservationDto>);
                }}
              />
              <AdditionalOptions
                listingId={Number(listingId)}
                onChange={(options) => {
                  const optionsDto = options.map((option) => ({
                    id: option.id,
                    qty: option.qty,
                  }));

                  setState('options', optionsDto);
                  setSelectedOptions(options);
                }}
              />
              <Agreements
                onCheck={(checked) => {
                  setIsAgreementChecked(checked);
                }}
              />
              <DiscountSection
                voucher={voucher}
                coupon={checkedCoupon}
                onCloseDiscount={(type) => {
                  if (type === 'voucher') {
                    setVoucher(undefined);
                    updateServiceModel('voucherCode', '');
                  } else {
                    setCheckedCoupon(undefined);
                    updateServiceModel('couponCode', '');
                  }
                }}
                memberInfo={myInfo}
                onSearch={(type, value) => {
                  handleClickDiscountItem(type, value);
                }}
              />

              {/* <div style={{ marginTop: 40 }}> */}
              <PaymentMethods onChange={(isForeignCard) => setIsForeignCard(isForeignCard)} />
              {/* </div> */}
              <BoxWrapper style={{ marginTop: 48, paddingBottom: 0 }}>
                <CancellationPolicy style={{ marginBottom: 10 }} />
              </BoxWrapper>
              <BoxWrapper style={{ marginTop: 12, paddingBottom: 0 }}>
                <BodyText>{t('request')}</BodyText>
                <InputField
                  multiline
                  rows={4}
                  placeholder={t('requestPlaceholder')}
                  style={{ backgroundColor: '#F8F8F8', marginTop: 10, borderRadius: 8 }}
                  onChange={(value: string) => {
                    updateServiceModel('guestNote', value);
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                  }}
                />
                <CaptionText
                  type="A"
                  style={{ color: '#8E8E8E', padding: '10px 0', fontWeight: 400 }}
                >
                  {t('not available extra service')}
                </CaptionText>
              </BoxWrapper>
              <style>
                {`
                    .MuiAccordionDetails-root {
                        padding: 0;
                    }
                    .MuiAccordionSummary-root {
                        padding: 0;
                    }
                    `}
              </style>
            </FormContainer>
            {/* </div> */}
          </div>
          <PriceWrapper style={{ position: 'sticky', top: 140 }}>
            <div style={{ marginBottom: 10, fontSize: 17, fontWeight: 700 }}>
              {t('price detail')}
            </div>
            <PriceDetail
              changePrice={(price: number) => {
                updateServiceModel('totalAmount', price);
              }}
              options={selectedOptions}
              priceInfo={listingPrice}
              point={stateModel?.usePoint}
              voucher={voucher}
              coupon={checkedCoupon?.coupon}
            />
            <Separator />
            <AgreementForm
              onCheck={(value: boolean) => {
                setIsTermsChecked(value);
              }}
            />
            <BTButton
              disabled={!isValid}
              busy={busy}
              onClick={createReservation}
              style={{ width: '100%', marginBottom: isMobile ? '20px' : '0' }}
            >
              {t('book')}
            </BTButton>
          </PriceWrapper>
        </RowWrapper>
      </div>
    </PageContainer>
  );
};

const Separator = styled.div`
  margin-top: 32px;
  border-top: 1px solid var(--Grayscale-10, #f0f0f0);
  display: flex;
  padding-top: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
`;

const RowWrapper = styled(Row)`
  @media ${(props) => props.theme.media.mobile} {
    flex-direction: column;
  }
`;

const BoxWrapper = styled(Box)`
  width: 100%;
  box-shadow: none;
  border: 1px solid var(--Grayscale-10, #e4e4e4);
`;

const PriceWrapper = styled.div`
  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;

const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TabNameContainer = styled.div`
  @media ${(props) => props.theme.media.mobile} {
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    width: 100vw;
  }
`;

const FormContainer = styled.div`
  max-width: 792px;
  margin-top: 40px;

  @media ${(props) => props.theme.media.mobile} {
    max-width: 100%;
    width: 100%;
    padding: 0;
  }
`;

export default BookPage;
