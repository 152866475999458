import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import CloseIcon from '@mui/icons-material/Close';
import { ListingDto } from 'services/data-contracts';

interface GalleryViewerProps {
  listing?: ListingDto;
  onClose: () => void;
}

const GalleryViewer = ({ listing, onClose }: GalleryViewerProps) => {
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    if (!listing?.images) return;

    const filteredImages = listing.images
      // 일반 이미지를 먼저, isPlan 이미지를 나중에 정렬
      .sort((a, b) => {
        if (a.isPlan && !b.isPlan) return 1;
        if (!a.isPlan && b.isPlan) return -1;
        return 0;
      })
      .map((image) => image.url || '')
      .filter((url) => url !== '');

    setImages(filteredImages);
  }, [listing]);

  return (
    <Overlay>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      <GalleryContainer>
        {images.map((imageUrl, index) => (
          <ImageWrapper
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <GalleryImage src={imageUrl} alt={`Gallery image ${index + 1}`} />
          </ImageWrapper>
        ))}
      </GalleryContainer>
    </Overlay>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  overflow-y: auto;
`;

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
  gap: 20px;
`;

const ImageWrapper = styled(motion.div)`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const GalleryImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

const CloseButton = styled.button`
  position: fixed;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1001;

  svg {
    color: white;
    font-size: 24px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

export default GalleryViewer;
