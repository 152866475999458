import * as React from 'react';
import styled from 'styled-components';
import { ReservationHeader } from './ReservationHeader';
import { AccommodationDetails } from './AccommodationDetails';
import { ReservationDetails } from './ReservationDetails';
import { useEffect, useState } from 'react';
import ServiceHelper from 'stores/ServiceHelper';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PaymentDto, ReservationDto, ListingDto } from 'services/data-contracts';
import i18n from 'i18n';
import useToken from 'hooks/useToken';
import { getUrlParam, convertStringToDate, getFormattedAddress } from 'libs/helper/utils';

const ReservationConfirmation: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [reservationInfo, setReservationInfo] = useState<ReservationDto | null>(null);
  const [listingInfo, setListingInfo] = useState<ListingDto | null>(null);
  const [orderId, setOrderId] = useState<string | null>(null);

  useEffect(() => {
    // URL 파라미터에서 reservationKey 가져오기
    const reservationKey = getUrlParam('reservationkey');

    if (reservationKey) {
      // 로컬 스토리지에서 예약 정보 가져오기
      const reservationInfo = localStorage.getItem('reservation' + reservationKey);

      if (reservationInfo) {
        const parsedInfo = JSON.parse(reservationInfo);
        setReservationInfo(parsedInfo);
        console.log('parsedInfo:', parsedInfo);

        // 예약 정보가 있다면 추가로 숙소 정보 가져오기
        if (parsedInfo.listingId) {
          ServiceHelper.listingService
            ?.getListingById(Number(parsedInfo.listingId), { locale: i18n.language })
            .then((res) => {
              setListingInfo(res.data);
            });
        }
        localStorage.removeItem('reservation' + reservationKey);
      }
    } else {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    const orderId = getUrlParam('orderId');
    if (orderId) {
      setOrderId(orderId);
    }
  }, []);

  return (
    <Container>
      <ContentWrapper>
        <ReservationHeader reservationNumber={orderId ?? ''} />
        <DetailsSection>
          <AccommodationDetails
            name={reservationInfo?.listingName ?? ''}
            address={listingInfo?.address ?? ''}
            imageUrl={listingInfo?.images?.find((image) => !image.isPlan)?.url ?? ''}
          />
          <ReservationDetails
            checkInDate={convertStringToDate(reservationInfo?.arrivalDate ?? '') ?? ''}
            checkOutDate={convertStringToDate(reservationInfo?.departureDate ?? '') ?? ''}
            numberOfGuests={reservationInfo?.totalGuests ?? 0}
            totalAmount={reservationInfo?.totalAmount ?? 0}
          />
          <NoticeMessage>
            {t('reservationComplete.reservationConfirmation.noticeMessage')}
          </NoticeMessage>
        </DetailsSection>
      </ContentWrapper>
      {/* <ActionSection>
        <SearchButton>검색하기</SearchButton>
      </ActionSection> */}
    </Container>
  );
};

const Container = styled.main`
  display: flex;
  max-width: 588px;
  flex-direction: column;
  font-family: Pretendard, sans-serif;
  justify-content: space-between;
  padding: 32px 0;
  margin: 0 auto;
`;

const ContentWrapper = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const DetailsSection = styled.div`
  border-radius: 0;
  display: flex;
  margin-top: 48px;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const NoticeMessage = styled.div`
  text-align: left;
  color: var(--Grayscale-50, #8e8e8e);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.03px;
  margin-top: 16px;
  word-break: keep-all;
`;

const ActionSection = styled.div`
  display: flex;
  margin-top: 452px;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const SearchButton = styled.button`
  border-radius: 8px;
  background: var(--4-e-433-c, #4e433c);
  min-height: 48px;
  width: 100%;
  padding: 0 16px;
  border: none;
  color: var(--Grayscale-0, #fff);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.04px;
  cursor: pointer;

  &:focus {
    outline: 2px solid #000;
    outline-offset: 2px;
  }
`;

export default ReservationConfirmation;
